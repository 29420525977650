import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { headData } from '../text/data';
import '../style/main.scss';

export default () => {
  const { lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms and Conditions</title>
        <html lang={lang || 'en'} />
        <meta name="description" content="Terms and Conditions" />
      </Helmet>
      <Container style={{ textAlign: 'left', padding: '2rem' }}>
        <h1>Terms and Conditions</h1>
        <p>Last updated: February 10, 2022</p>
        <p>
          Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before
          using the https://www.ksrautomation.co website (the "Service") operated by KSR Automation LLC
          ("us", "we", or "our").
        </p>
        <p>
          Your access to and use of the Service is conditioned upon your acceptance of and
          compliance with these Terms. These Terms apply to all visitors, users and others who wish
          to access or use the Service.
        </p>
        <p>
          By accessing or using the Service you agree to be bound by these Terms. If you disagree
          with any part of the terms then you do not have permission to access the Service.
        </p>
        <h2>Purchases</h2>
        <p>
          If you wish to purchase any product or service made available through the Service
          ("Purchase"), you may be asked to supply certain information relevant to your Purchase
          including, without limitation, your credit card number, the expiration date of your credit
          card, your billing address, and your shipping information.
        </p>
        <p>
          You represent and warrant that: (i) you have the legal right to use any credit card(s) or
          other payment method(s) in connection with any Purchase; and that (ii) the information you
          supply to us is true, correct and complete.
        </p>
        <p>
          The service may employ the use of third party services for the purpose of facilitating
          payment and the completion of Purchases. By submitting your information, you grant us the
          right to provide the information to these third parties subject to our Privacy Policy.
        </p>
        <p>
          We reserve the right to refuse or cancel your order at any time for reasons including but
          not limited to: product or service availability, errors in the description or price of the
          product or service, error in your order or other reasons.
        </p>
        <p>
          We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal
          transaction is suspected.
        </p>
        <h2>Returns, Refunds, and Cancellations</h2>
        <p>
          All software sold by KSR Automation LLC is FINAL SALE. We do not allow returns or refunds, 
          EXCEPT at the owner's discretion. Any returns or refunds are subject to a small fee of up
          to 3% of the purchase price, which covers any payment processing fees. If the software is
          sold as a subscription, all users have the ability to cancel their subscription at any time
          through our website dashboard user managemenet system, which can be found at <a href="https://dash.ksrautomation.co/">https://dash.ksrautomation.co/</a> following authentication of the user's discord account.
        </p>
        <h2>Renting and Reselling of Software</h2>
        <p>
          All software sold by KSR Automation LLC is not permitted to be rented or re-sold BY users or anyone else,
          TO any other customer, organization, or 3rd party. If any license to our software is found to be rented
          or "re-sold" to a 3rd-party, we reserve the right to cancel said license at any time, without
          any notification or refund.
        </p>
        <h2>Availability, Errors and Inaccuracies</h2>
        <p>
          We are constantly updating product and service offerings on the Service. We may experience
          delays in updating information on the Service and in our advertising on other web sites.
          The information found on the Service may contain errors or inaccuracies and may not be
          complete or current. Products or services may be mispriced, described inaccurately, or
          unavailable on the Service and we cannot guarantee the accuracy or completeness of any
          information found on the Service.
        </p>
        <p>
          We therefore reserve the right to change or update information and to correct errors,
          inaccuracies, or omissions at any time without prior notice.
        </p>
        <h2>Intellectual Property</h2>
        <p>
          The Service and its original content, features and functionality are and will remain the
          exclusive property of KSR Automation LLC and its licensors. The Service is protected by
          copyright, trademark, and other laws of both the United States and foreign countries. Our
          trademarks and trade dress may not be used in connection with any product or service
          without the prior written consent of KSR Automation LLC.
        </p>
        <h2>Links To Other Web Sites</h2>
        <p>
          Our Service may contain links to third party web sites or services that are not owned or
          controlled by KSR Automation LLC
        </p>
        <p>
          KSR Automation LLC has no control over, and assumes no responsibility for the content,
          privacy policies, or practices of any third party web sites or services. We do not warrant
          the offerings of any of these entities/individuals or their websites.
        </p>
        <p>
          You acknowledge and agree that KSR Automation LLC shall not be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be caused by or in
          connection with use of or reliance on any such content, goods or services available on or
          through any such third party web sites or services.
        </p>
        <p>
          We strongly advise you to read the terms and conditions and privacy policies of any third
          party web sites or services that you visit.
        </p>
        <h2>Termination</h2>
        <p>
          We may terminate or suspend your access to the Service immediately, without prior notice
          or liability, under our sole discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the Terms.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive termination shall survive
          termination, including, without limitation, ownership provisions, warranty disclaimers,
          indemnity and limitations of liability.
        </p>
        <h2>Indemnification</h2>
        <p>
          You agree to defend, indemnify and hold harmless KSR Automation LLC and its licensee and
          licensors, and their employees, contractors, agents, officers and directors, from and
          against any and all claims, damages, obligations, losses, liabilities, costs or debt, and
          expenses (including but not limited to attorney's fees), resulting from or arising out of
          a) your use and access of the Service, or b) a breach of these Terms.
        </p>
        <h2>Limitation Of Liability</h2>
        <p>
          In no event shall KSR Automation LLC, nor its directors, employees, partners, agents,
          suppliers, or affiliates, be liable for any indirect, incidental, special, consequential
          or punitive damages, including without limitation, loss of profits, data, use, goodwill,
          or other intangible losses, resulting from (i) your access to or use of or inability to
          access or use the Service; (ii) any conduct or content of any third party on the Service;
          (iii) any content obtained from the Service; and (iv) unauthorized access, use or
          alteration of your transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we have been informed of
          the possibility of such damage, and even if a remedy set forth herein is found to have
          failed of its essential purpose.
        </p>
        <h2>Disclaimer</h2>
        <p>
          Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and
          "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether
          express or implied, including, but not limited to, implied warranties of merchantability,
          fitness for a particular purpose, non-infringement or course of performance.
        </p>
        <p>
          KSR Automation LLC, its subsidiaries, affiliates, and its licensors do not warrant that a)
          the Service will function uninterrupted, secure or available at any particular time or
          location; b) any errors or defects will be corrected; c) the Service is free of viruses or
          other harmful components; or d) the results of using the Service will meet your
          requirements.
        </p>
        <h2>Exclusions</h2>
        <p>
          Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or
          limitation of liability for consequential or incidental damages, so the limitations above
          may not apply to you.
        </p>
        <h2>Governing Law</h2>
        <p>
          These Terms shall be governed and construed in accordance with the laws of Texas, United
          States, without regard to its conflict of law provisions.
        </p>
        <p>
          Our failure to enforce any right or provision of these Terms will not be considered a
          waiver of those rights. If any provision of these Terms is held to be invalid or
          unenforceable by a court, the remaining provisions of these Terms will remain in effect.
          These Terms constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have had between us regarding the
          Service.
        </p>
        <h2>Changes</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any
          time.
        </p>
        <p>
          By continuing to access or use our Service after any revisions become effective, you agree
          to be bound by the revised terms. If you do not agree to the new terms, you are no longer
          authorized to use the Service.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at
          ksroboticsautomation@gmail.com
        </p>
      </Container>
    </>
  );
};
